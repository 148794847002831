import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { Capacitor } from '@capacitor/core';

export const isNativeGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);

  if (Capacitor.isNativePlatform()) {
    return true; // Allow navigation in native environments
  }

  console.warn('Access blocked: This feature is only available in the native app.');
  if(route.url[0].path == "smart-plug-discovery") {
    router.navigate(['/add-device']); // Redirect to login page
  } else {
    router.navigate(['/login']); // Redirect to login page
  }

  return false;
};

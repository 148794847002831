import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from './guards/authentication.guard';
import { LoginGuard } from './guards/login.guard';
import { AdministratorGuard } from './guards/administrator.guard';
import { isNativeGuard } from './guards/is-native.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => 
      import('./public/login/login.module').then( m => m.LoginPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'signup',
    loadChildren: () => 
      import('./public/signup/signup.module').then( m => m.SignupPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'support',
    loadChildren: () => import('./members/support/support.module').then( m => m.SupportPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'about-app',
    loadChildren: () => import('./members/about-app/about-app.module').then( m => m.AboutAppPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'firmware',
    loadChildren: () => import('./members/firmware/firmware.module').then( m => m.FirmwarePageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'your-account',
    loadChildren: () => import('./members/your-account/your-account.module').then( m => m.YourAccountPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'releases',
    loadChildren: () => import('./members/releases/releases.module').then( m => m.ReleasesPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'device-details/:id',
    loadChildren: () => import('./members/device-details/device-details.module').then( m => m.DeviceDetailsPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'client-details/:id',
    loadChildren: () => import('./members/client-details/client-details.module').then( m => m.ClientDetailsPageModule),
    canActivate: [AuthenticationGuard]
  },
  // {
    // path: 'site-details/:id',
    // loadChildren: () => import('./members/site-details/site-details.module').then( m => m.SiteDetailsPageModule),
    // canActivate: [AuthenticationGuard]
  // },
  {
    path: 'customer-details/:id',
    loadChildren: () => import('./members/customer-details/customer-details.module').then( m => m.CustomerDetailsPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'add-admin',
    loadChildren: () => import('./members/add-admin/add-admin.module').then( m => m.AddAdminPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'add-device',
    loadChildren: () => import('./members/add-device/add-device.module').then( m => m.AddDevicePageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'add-network',
    loadChildren: () => import('./members/add-network/add-network.module').then( m => m.AddNetworkPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'admins',
    loadChildren: () => import('./members/admins/admins.module').then( m => m.AdminsPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'event-details/:id',
    loadChildren: () => import('./members/event-details/event-details.module').then( m => m.EventDetailsPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'inventory',
    loadChildren: () => import('./members/inventory/inventory.module').then( m => m.InventoryPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'logs',
    loadChildren: () => import('./members/logs/logs.module').then( m => m.LogsPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'manage-firmware',
    loadChildren: () => import('./members/manage-firmware/manage-firmware.module').then( m => m.ManageFirmwarePageModule),
    canActivate: [AdministratorGuard]
  },
  {
    path: 'metrics',
    loadChildren: () => import('./members/metrics/metrics.module').then( m => m.MetricsPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./members/settings/settings.module').then( m => m.SettingsPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'readyon-settings',
    loadChildren: () => import('./members/readyon-settings/readyon-settings.module').then( m => m.ReadyonSettingsPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'template',
    loadChildren: () => import('./members/template/template.module').then( m => m.TemplatePageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'app',
    loadChildren: () => import('./members/split-layout/split-layout.module').then( m => m.SplitLayoutPageModule)
  },
  {
    path: 'configuration-templates',
    loadChildren: () => import('./members/configuration-templates/configuration-templates.module').then( m => m.ConfigurationTemplatesPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'access-point-details/:id',
    loadChildren: () => import('./members/access-point-details/access-point-details.module').then( m => m.AccessPointDetailsPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'technical-support',
    loadChildren: () => import('./members/technical-support/technical-support.module').then( m => m.TechnicalSupportPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'requests',
    loadChildren: () => import('./members/requests/requests.module').then( m => m.RequestsPageModule),
	canActivate: [AuthenticationGuard]
  },
  {
    path: 'site-details/:id',
    loadChildren: () => import('./members/site-side-menu/site-side-menu.module').then( m => m.SiteSideMenuPageModule)
  },
  {
    path: 'smart-plug-details/:id',
    loadChildren: () => import('./members/smart-plug-details/smart-plug-details.module').then( m => m.SmartPlugDetailsPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'smart-plug-discovery',
    loadChildren: () => import('./members/smart-plug-discovery/smart-plug-discovery.module').then( m => m.SmartPlugDiscoveryPageModule),
    canActivate: [AuthenticationGuard, isNativeGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
